import React, { useRef, useState } from "react";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Tabs from "../../components/tabs/Tabs";
import TabLink from "../../components/tabs/TabLink";
import TabContent from "../../components/tabs/TabContent";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";

import Layout from "../../components/mschool/layout";
import SEO from "../../components/seo";
import Card from "../../components/cardmodel";
import SchoolCard from "../../components/schoollicensecardmodel";
import TopBanner from "../../components/mschool/topbanner";

import Power from "../../components/mschool/power";
import ProInstructors from "../../components/mschool/proinstructors";
import useWindowSize from "../../hooks/useWindowSize";
import settings from "../../../settings";
import NormalAgenda from "../../components/mschool/TwoDayNormalAgenda";
import SummerAgenda from "../../components/mschool/TwoDaySummerAgenda";
import Track from "../../components/track";
// banner
const banner =
	`${settings.IMAGES_BASE_URL}/v2/images/two-day-m/two-day-m-banner.jpg`;
const bannerMobile =
	`${settings.IMAGES_BASE_URL}/v2/images/two-day-m/two-day-m-banner.jpg`;
// locations
// classes
const advancedmschool =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/advanced-m-school-banner%201.png`;
const mdriver =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/m-drivers-class.jpg`;
const onedaymschool =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/one-day-m-class.jpg`;
const m4gt4school =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/m4-gt4-class.jpg`;
const racelicense =
	`${settings.IMAGES_BASE_URL}/v2/images/class-thumbnails/race-license-class.jpg`;
const indyfullday =
	`${settings.IMAGES_BASE_URL}/v2/images/indy_images/full-day-indy-box.jpg`;

const southcarolina =
	`${settings.IMAGES_BASE_URL}/v2/images/two-day-m/sc-location.jpg`;
const thermal =
	`${settings.IMAGES_BASE_URL}/v2/images/two-day-m/california-location.jpg`;

const drifting =
	`${settings.IMAGES_BASE_URL}/v2/images/two-day-m/drifting.jpg`;
const figure =
	`${settings.IMAGES_BASE_URL}/v2/images/two-day-m/figure-8.jpg`;
const highspeed =
	`${settings.IMAGES_BASE_URL}/v2/images/two-day-m/high-speed-track.jpg`;
const autocross =
	`${settings.IMAGES_BASE_URL}/v2/images/two-day-m/autocross.jpg`;
const ratrace =
	`${settings.IMAGES_BASE_URL}/v2/images/two-day-m/rat-race.jpg`;
const timeattack =
	`${settings.IMAGES_BASE_URL}/v2/images/two-day-m/time-atack.jpg`;


const TwoDayMSchoolPage = () => {
	const scrollToView = (ref) => {
		ref.current.scrollIntoView({
			behavior: "smooth",
		});
	};
	const { width } = useWindowSize();
	const isMobile = width < 1200;
	const isDesktop = width >= 1200;
	const learnRef = useRef(null);
	const locationsRef = useRef(null);
	const powerRef = useRef(null);
	const instructorsRef = useRef(null);
	const classesRef = useRef(null);
  const [openAgendas, setOpenAgendas] = useState({ isSummerAgendaOpen: false, isNormalAgendaOpen: false })
  const viewNormalHours = () => {
    setOpenAgendas({ isNormalAgendaOpen: !openAgendas.isSummerAgendaOpen, isSummerAgendaOpen: false });
  };
  const viewSummerHours = () => {
    setOpenAgendas({ isSummerAgendaOpen: !openAgendas.isSummerAgendaOpen });
  }

	return (
		<Layout>
			<SEO
				title="BMW M Performance Driving School | Two-Day M School"
				description="Take one M car. Add water, subtract grip. Feel the difference between under- and oversteer, and learn to control the back of the car with throttle."
				keywords="bmw m school, m driving school, bmw m driving school, bmw m performance driving school"
			/>
			<div className="twodaymschoolpage">
				<TopBanner
					image={banner}
					mobileImage={bannerMobile}
					header="TWO-DAY M SCHOOL"
					subheader="BECOME MASTER OF M"
					pricing="FROM $3,995"
					text="Settle into the M Sport Seat, grab the wheel, take a deep breath and step on it. M School is all about limits, and you’ll discover just how high we set them over two days of adrenaline-pumping exercises designed to bring out your inner M."
					buttonText="Book in California"
					butttonURL="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=M&class=Two_Day_M_School"
					buttonTwoText="Book in South Carolina"
					butttonTwoURL="https://pds.eventsbmw.com/?location=Spartanburg%2C%20SC&brand=M&class=Two_Day_M_School"
					showbuttonTwoTextInMob="true"
          agendaPopup={
          <NormalAgenda
            viewSummerHours={viewSummerHours}
            openAgendas={openAgendas}
          />}
				>
          <p>
            Please note the Summer hours for our Performance Center West location only. Classes in Thermal will begin at 6AM, June 1st through September 30th. Please check your confirmation email for details.
          </p>
        </TopBanner>
        <SummerAgenda
            viewNormalHours={viewNormalHours}
            openAgendas={openAgendas}
            setOpenAgendas={setOpenAgendas}
        />
				<section className="links__tabs">
					<div>
						<ul>
							<li>
								<button
									onClick={() => {
										scrollToView(learnRef);
									}}
								>
									What you’ll learn
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(locationsRef);
									}}
								>
									Locations
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(powerRef);
									}}
								>
									What you’ll drive
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(instructorsRef);
									}}
								>
									Pro instructors
								</button>
							</li>
							<li>
								<button
									onClick={() => {
										scrollToView(classesRef);
									}}
								>
									Other classes
								</button>
							</li>
						</ul>
					</div>
				</section>
				<section className="learn" ref={learnRef}>
					<h2>WHAT YOU’LL LEARN</h2>
					{isMobile && (
						<Accordion atomic={true}>
							<AccordionItem title="Drifting">
								<LazyLoadImage effect="blur" src={drifting} alt="Drifting" />
								<div className="accordion-content">
									<h3>Slip, then grip</h3>
									<p>
										Take one M car. Add water, subtract grip. Feel the
										difference between under- and oversteer, and learn to
										control the back of the car with throttle. Get it right, and
										you’ll start to glide through our polished concrete skidpad
										with ease.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Figure 8">
								<LazyLoadImage effect="blur" src={figure} alt="Figure 8" />
								<div className="accordion-content">
									<h3>Don’t Hesit8</h3>
									<p>
										Like the Rat Race, this exercise is on wet roads with no
										traction control. Drive the track in a figure 8 and learn
										that patience is key. Too much throttle, and you’ll spin
										out. Who knew mistakes could be so much fun.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Autocross">
								<LazyLoadImage effect="blur" src={autocross} alt="Autocross" />
								<div className="accordion-content">
									<h3>Technical Perfection</h3>
									<p>
										These tracks are set up to be short, with aggressive turns
										that require a balance between throttle and brake. You’ll
										learn this dance by looking as far ahead as possible and
										planning the proper drive lines through compromise corners.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="High Speed Track">
								<LazyLoadImage
									effect="blur"
									src={highspeed}
									alt="High Speed Track"
								/>
								<div className="accordion-content">
									<h3>Floor it</h3>
									<p>
										Things ramp up for two-day M School. The tracks get longer
										and more technical, and your ability to maintain focus will
										be tested. Cycle through multiple M cars and experience high
										speeds for sustained periods.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Rat Race">
								<LazyLoadImage effect="blur" src={ratrace} alt="Rat Race" />
								<div className="accordion-content">
									<h3>Claw for first</h3>
									<p>
										Wet tarmac. An oval track. And you, lined up on the opposite
										side of your foe. Step on it and see if you can catch up to
										them. But be alert - no traction control allowed here.
									</p>
								</div>
							</AccordionItem>
							<AccordionItem title="Time Attack">
								<LazyLoadImage
									effect="blur"
									src={timeattack}
									alt="Time Attack"
								/>
								<div className="accordion-content">
									<h3>Race the Clock</h3>
									<p>
										Once you’ve mastered the autocross section, you’ll head back
										in the afternoon for some timed runs against the other
										drivers. Smoothness is the key here. Win the day and you’ll
										be Master of M.
									</p>
								</div>
							</AccordionItem>
						</Accordion>
					)}
					<div className="container">
						{isDesktop && (
							<Tabs>
								<div className="learn__images">
									<TabContent for="drifting" uniqueKey="1">
										<img
											src={drifting}
											alt="Drifting"
										/>
									</TabContent>
									<TabContent for="figure" uniqueKey="2">
										<img src={figure} alt="Figure 8" />
									</TabContent>
									<TabContent for="autocross" uniqueKey="3">
										<img
											src={autocross}
											alt="Learn the Line"
										/>
									</TabContent>
									<TabContent for="high-speed" uniqueKey="4">
										<img
											src={highspeed}
											alt="High Speed Track"
										/>
									</TabContent>
									<TabContent for="rat-race" uniqueKey="5">
										<img src={ratrace} alt="Rat Race" />
									</TabContent>
									<TabContent for="time-attack" uniqueKey="6">
										<img
											src={timeattack}
											alt="Time Attack"
										/>
									</TabContent>
								</div>
								<ul className="learn__menu" role="tablist" aria-owns="drifting autocross high-speed time-attack rat-race figure" aria-busy="true">
									<li role="presentation">
										<TabLink role="tab" id="drifting" to="drifting" uniqueKey="7">Drifting</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="figure" to="figure" uniqueKey="8">Figure 8</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="autocross" to="autocross" uniqueKey="9">Autocross</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="high-speed" to="high-speed" uniqueKey="10">High-Speed Track</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="rat-race" to="rat-race" uniqueKey="11">Rat Race</TabLink>
									</li>
									<li role="presentation">
										<TabLink role="tab" id="time-attack" to="time-attack" uniqueKey="12">Time Attack</TabLink>
									</li>
								</ul>
								<div className="learn__content">
									<TabContent for="drifting" uniqueKey="13">
										<h3>Slip, then grip</h3>
										<p>
											Take one M car. Add water, subtract grip. Feel the
											difference between under- and oversteer, and learn to
											control the back of the car with throttle. Get it right,
											and you’ll start to glide through our polished concrete
											skidpad with ease.
										</p>
									</TabContent>
									<TabContent for="figure" uniqueKey="14">
										<h3>Don’t Hesit8</h3>
										<p>
											Like the Rat Race, this exercise is on wet roads with no
											traction control. Drive the track in a figure 8 and learn
											that patience is key. Too much throttle, and you’ll spin
											out. Who knew mistakes could be so much fun.
										</p>
									</TabContent>
									<TabContent for="autocross" uniqueKey="15">
										<h3>Technical Perfection</h3>
										<p>
											These tracks are set up to be short, with aggressive turns
											that require a balance between throttle and brake. You’ll
											learn this dance by looking as far ahead as possible and
											planning the proper drive lines through compromise
											corners.
										</p>
									</TabContent>
									<TabContent for="high-speed" uniqueKey="16">
										<h3>Floor it</h3>
										<p>
											Things ramp up for two-day M School. The tracks get longer
											and more technical, and your ability to maintain focus
											will be tested. Cycle through multiple M cars and
											experience high speeds for sustained periods.
										</p>
									</TabContent>
									<TabContent for="rat-race" uniqueKey="17">
										<h3>Claw for first</h3>
										<p>
											Wet tarmac. An oval track. And you, lined up on the
											opposite side of your foe. Step on it and see if you can
											catch up to them. But be alert - no traction control
											allowed here.
										</p>
									</TabContent>
									<TabContent for="time-attack" uniqueKey="18">
										<h3>Race the Clock</h3>
										<p>
											Once you’ve mastered the autocross section, you’ll head
											back in the afternoon for some timed runs against the
											other drivers. Smoothness is the key here. Win the day and
											you’ll be Master of M.
										</p>
									</TabContent>
								</div>
							</Tabs>
						)}
					</div>
				</section>
				<Track ref={locationsRef} variant="black" />
				<Power ref={powerRef} />
				<ProInstructors ref={instructorsRef} />
				<section className="accomodation">
					<div className="container">
						<h2>MAKE A DATE WITH SPEED AND POWER</h2>
						<div className="accomodation__content">
							<div className="row">
								<div className="accomodation__item">
									<LazyLoadImage
										effect="blur"
										src={southcarolina}
										alt="power item"
									/>
									<h3>Spartanburg, South Carolina</h3>
									<p>
										Our East Coast location offers four beautiful seasons and
										Southern charm to make your trip a memorable one. Book with
										one of our partners for special rates.
									</p>
									<Link to="/hotelpartners">
										<button className="btn btn-bordered">
											See hotel partner list
										</button>
									</Link>
								</div>
								<br />
								<div className="accomodation__item">
									<LazyLoadImage effect="blur" src={thermal} alt="power item" />
									<h3>Thermal, California</h3>
									<p>
										Experience sunny Palm Springs and the surrounding area, from
										luxurious resorts to fine dining, golf and more. Book with
										one of our partners for special rates.
									</p>
									<Link to="/hotelpartners">
										<button className="btn btn-bordered">
											See hotel partner list
										</button>
									</Link>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<Card
										title="TWO-DAY m school"
										subtitle=""
										price={"From $3,995"}
										another_price=" "
										type="two_day"
										text={`You can book online or through our reservation line at 888-345-4269, or call by clicking below.`}
										url="https://pds.eventsbmw.com/?location=Spartanburg%2C%20SC&brand=M&class=Two_Day_M_School"
									/>
								</div>
								<div className="col">
									<Card
										title="TWO-DAY m school"
										subtitle=""
										type="two_day"
										price={"From $3,995"}
										another_price=" "
										text="You can book online or through our reservation line at 888-345-4269, or call by clicking below."
										url="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=M&class=Two_Day_M_School"
									/>
									<SchoolCard
										title="Add Race license school"
										subtitle="Participants must have completed either the Two-Day M School or Advanced M School within 12 months of taking this course."
										price="$2,495"
										another_price=" "
										hide_links="true"
										text="You can book only through our reservation line."
										url="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=M&class=Two_Day_M_School"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="other-schools" ref={classesRef}>
					<div className="container">
						<h2>YOU MAY ALSO LIKE</h2>
					</div>
					<div className="other-schools__content row">
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={onedaymschool}
								alt="One-Day M School"
							/>
							<h3>One-Day M School</h3>
							<p>The basics of performance are anything but basic.</p>
							<Link to="/mschool/onedaymschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={advancedmschool}
								alt="Advanced M School"
							/>
							<h3>Advanced M School</h3>
							<p>The faint of heart need not apply.</p>
							<Link to="/mschool/advancedmschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={racelicense}
								alt="Race License School"
							/>
							<h3>Race License School</h3>
							<p>Become a pro.</p>
							<Link to="/mschool/racelicenseschool">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={mdriver}
								alt="M Driver’s Program"
							/>
							<h3>M Driver’s Program</h3>
							<p>Take yourself to the limit.</p>
							<Link to="/mschool/mdriver">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={m4gt4school}
								alt="M4 GT4 School"
							/>
							<h3>M4 GT4 School</h3>
							<p>Learn to drive the ultimate BMW M.</p>
							<Link to="/mschool/m4gt4">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
						<div className="other-schools__item">
							<LazyLoadImage
								effect="blur"
								src={indyfullday}
								alt="Full-Day M Experience at Indy"
							/>
							<h3>Full-Day M Experience at Indy</h3>
							<p>Full-on thrills on a hallowed track.</p>
							<Link to="/indy-fullday">
								<button className="btn btn-bordered">Learn More</button>
							</Link>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default TwoDayMSchoolPage;
